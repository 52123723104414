import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import LoadScript from "vue-plugin-load-script";
import "vue-toastification/dist/index.css";
import "./assets/css/tailwind.css";
import ApiService from "@/common/api.service";
import vue3GoogleLogin from "vue3-google-login";
ApiService.init();

let CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  // window.webengage.options('webpush.disablePrompt', true);
  // window.webengage.options("safariWebPush", true);
  store.dispatch("addPageLoading");
  Promise.all([store.dispatch("checkAuth")]).then(next());
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("pageLoadingComplete"), 100);
});

const app = createApp(App);

const options = {
  // You can set your default options here
  position: POSITION.TOP_CENTER,
};

app.use(Toast, options);
app.use(store);
app.use(router);
app.use(LoadScript);
app.use(vue3GoogleLogin, {
  clientId:CLIENT_ID
});
app.mount("#app");
