import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import store from "@/store";
import BaseRoutes from "./BaseRoutes";

const routes = [
  ...BaseRoutes,
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: "Login" });
    } else if (to.name === "Login") {
      next({ name: "Catalog" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
